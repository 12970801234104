import { APIHooksKeysEnum } from 'enums/APIHooksKeysEnum'
import { useMutation } from 'react-query'
import { accountService } from 'services/account.service'

export const track = async (inviteToken: string) => {
  const { data } = await accountService.trackAffiliateClick(inviteToken)

  return data
}

export const useTrackAffiliateClick = () => {
  const mutation = useMutation(APIHooksKeysEnum.TRACK_AFFILIATE_CLICK, track)

  return mutation
}
