import React from 'react'
import styles from './SingleStat.module.scss'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'

interface Props {
  title: string
  desc: string
}

export const SingleStat: React.FC<Props> = ({ title, desc }) => {
  return (
    <FlexColumn className={styles.column}>
      <FlexRow gap="10px">
        <div className={styles.dot} />
        <span className={styles.big}>{title}</span>
      </FlexRow>
      <span className={styles.small}>{desc}</span>
    </FlexColumn>
  )
}
