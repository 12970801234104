import React from 'react'
import { Container, FlexRow } from 'components/common/Containers'
import { Button } from 'components/common/Buttons/Button'
import APIImg from './images/api.svg'
import styles from './APIDocumentation.module.scss'
import Link from 'next/link'
import { RouteEnum } from '../../../enums/RouteEnum'

const APIDocumentation: React.FC = () => {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <FlexRow className={styles.row} justify="space-between" gap="2rem">
          <div className={styles.info}>
            <span className={styles.subtitle}>Good news</span>
            <strong className={styles.title}>API Documentation</strong>
            <p className={styles.text}>
              Powerful. Flexible. Integrate our data into your system or
              solution with full-fledged APIs. Enjoy the user-friendly platform.
              Or use both.
            </p>
            <p className={styles.text} style={{marginBottom: "1rem"}}>
              We understand that everyone has different preferences when it comes to domain tools. That is why we offer both a user-friendly platform and powerful APIs.
              Our flexible domain API lets you easily integrate our powerful features into your system.
            </p>
            <Link passHref href={RouteEnum.APIS} legacyBehavior>
              <Button>Learn more</Button>
            </Link>
          </div>
          <div>
            <APIImg />
          </div>
        </FlexRow>
      </Container>
    </section>
  )
}

export default APIDocumentation
