import React from 'react'
import styles from './CtaSection.module.scss'
import { PageLabel } from 'components/common/Texts/PageLabel/PageLabel'
import { Container } from 'components/common/Containers/Container/Container'
import { H2 } from 'components/common/Texts/H2/H2'
import { Button } from 'components/common/Buttons/Button'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import Link from 'next/link'
import { RouteEnum } from "../../../enums/RouteEnum";


interface Props {}

export const CtaSection: React.FC<Props> = ({}) => {
  return (
    <Container className={styles.container}>
      <FlexColumn className={styles.content}>
        <PageLabel>Metric and Insights</PageLabel>
        <H2>
          Get Better Insights and Skyrocket Your Revenue Today
        </H2>
        <p>
          stay on track as you plan, develop more and boost your business with
          our Exclusive features.
        </p>
        <Button mode="text" className={styles.textButton}>
          We created our tools for you!
        </Button>
        <Link passHref href={RouteEnum.LOGIN}>
        <Button rounded>
          Create a free account
        </Button>
        </Link>
      </FlexColumn>
    </Container>
  )
}
