import React from 'react'
import styles from './FAQSection.module.scss'
import { Container } from 'components/common/Containers/Container/Container'
import { H2 } from 'components/common/Texts/H2/H2'
import { SingleFAQ } from './SingleFAQ/SingleFAQ'
import classNames from 'classnames'

export interface FAQSectionProps {
  className?: string
  data: Array<{
    title: string
    text: string
    bullet_points?: string[]
  }>
}

const FAQSection: React.FC<FAQSectionProps> = ({ className, data }) => {
  const [openId, setOpenId] = React.useState(1)

  const onClick = (i) => {
    setOpenId(i)
  }

  return (
    <Container className={classNames(styles.container, className)}>
      <H2 className={styles.heading}>Frequently Asked Questions</H2>

      {data.map((d, i) => {
        return (
          <SingleFAQ
            key={i}
            open={openId === i}
            onClick={() => onClick(i)}
            faq={d}
          />
        )
      })}
    </Container>
  )
}

export default FAQSection
