import React from 'react'
import styles from './RightUnderlinedHeading.module.scss'
import { H2 } from 'components/common/Texts/H2/H2'

interface Props {}

export const RightUnderlinedHeading: React.FC<Props> = ({}) => {
  return (
    <H2 className={styles.heading}>
      Bishopi provides wide range of effective domain tools & services
    </H2>
  )
}
