import React, { HTMLAttributes, HTMLProps } from 'react'
import styles from './H1.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLHeadingElement> {}

export const H1: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <h1 className={classNames(styles.h1, className)} {...props}>
      {children}
    </h1>
  )
}
