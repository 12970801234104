import React, { HTMLAttributes } from 'react'
import styles from './H3.module.scss'
import classNames from 'classnames'

interface Props extends HTMLAttributes<HTMLHeadingElement> {}

export const H3: React.FC<Props> = ({ className, children, ...props }) => {
  return (
    <h3 className={classNames(styles.h3, className)} {...props}>
      {children}
    </h3>
  )
}
