import React from 'react'
import styles from './HeroCtaSection.module.scss'
import { Button } from 'components/common/Buttons/Button'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { BsArrowRight } from 'react-icons/bs'
import { HelperText } from 'components/common/Texts/HelperText/HelperText'
import Link from 'next/link'
import { RouteEnum } from '../../../../enums/RouteEnum'

interface Props {
  className?: string
}

export const HeroCtaSection: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <Link passHref href={RouteEnum.LOGIN} prefetch={false}>
        <Button size="lg">
          <FlexRow justify="center">
            Try Bishopi for Free <BsArrowRight className={styles.icon} />
          </FlexRow>
        </Button>
      </Link>
      {/* <HelperText>No credit card required</HelperText> */}
    </div>
  )
}
