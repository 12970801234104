import React from 'react'
import styles from './Hero.module.scss'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { H6 } from 'components/common/Texts/H6/H6'
import Image from 'next/image'
import { HeroCtaSection } from './HeroCtaSection/HeroCtaSection'
import { Container } from 'components/common/Containers/Container/Container'
import { AnimatedH1 } from './AnimatedH1/AnimatedH1'
import dynamic from 'next/dynamic'

const InteractiveLookup = dynamic(
  () => import('components/Home/InteractiveLookup/InteractiveLookup')
)
interface Props {}

export const Hero: React.FC<Props> = ({}) => {
  return (
    <section className={styles.main}>
      <Container className={styles.container}>
        <FlexColumn className={styles.left}>
          <AnimatedH1 />
          <H6 className={styles.topSubHeading}>
            Empower your domain investing business with the most advanced and
            powerful tools and services.
          </H6>

          <HeroCtaSection className={styles.ctaSection} />

          {/* <div>
            <H6 className={styles.subHeading}>
              Trusted by the world’s leading brands
            </H6>

            <FlexRow gap="2rem">
              <Image
                src="/images/home/brands1.png"
                alt="brands"
                width="370"
                height="43"
                loading="eager"
              />
              <Image
                src="/images/home/brands2.png"
                alt="brands"
                width="388"
                height="60"
                loading="eager"
              />
            </FlexRow>
          </div> */}
        </FlexColumn>

        <InteractiveLookup />
      </Container>
    </section>
  )
}
