import React from 'react'
import styles from './SingleFAQ.module.scss'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { BsPlusLg } from 'react-icons/bs'
import { BsDashLg } from 'react-icons/bs'
import classNames from 'classnames'

interface Props {
  open?: boolean
  onClick: () => any
  faq: {
    title: string
    text: string
    bullet_points?: string[]
  }
}

export const SingleFAQ: React.FC<Props> = ({
  open,
  onClick,
  faq,
  ...props
}) => {
  return (
    <div className={classNames(styles.faq, open && styles.open)} {...props}>
      <FlexRow
        className={styles.titleContainer}
        justify="space-between"
        onClick={onClick}
      >
        <p className={styles.title}>{faq.title}</p>
        {!open && <BsPlusLg />}
        {open && <BsDashLg />}
      </FlexRow>
      <div className={styles.text}>
        <p>{faq.text}</p>
        {faq?.bullet_points?.length > 0 && (
          <ul className={styles.bulletPoints}>
            {faq.bullet_points?.map((bullet, i) => (
              <li key={i}>{bullet}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
