import React from 'react'
import styles from './FeaturesSection.module.scss'
import Image from 'next/image'
import { Container } from 'components/common/Containers/Container/Container'
import data from './data.json'
import { FeatureTile } from './FeatureTile/FeatureTile'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { RightUnderlinedHeading } from './RightUnderlinedHeading/RightUnderlinedHeading'

interface Props {}

export const FeaturesSection: React.FC<Props> = ({}) => {
  return (
    <Container className={styles.container}>
      <div className={styles.featuresGrid}>
        {data.map((d, i) => {
          return (
            <FeatureTile
              data={d}
              key={d.id}
            />
          )
        })}
      </div>
      <FlexColumn className={styles.graphicContainer}>
        <RightUnderlinedHeading />
        <div className={styles.sideImageContainer}>
          <Image
            src="/images/home/features/side-image.svg"
            alt="icon"
            height="558"
            width="484"
          />
        </div>
      </FlexColumn>
    </Container>
  )
}
