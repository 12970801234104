import React from 'react'
import styles from './StatsSection.module.scss'
import { Container } from 'components/common/Containers/Container/Container'
import { SingleStat } from './SingleStat/SingleStat'

interface Props {}

export const StatsSection: React.FC<Props> = ({}) => {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.grid}>
          <SingleStat title="400M" desc="Registered domain names" />
          <SingleStat title="4M" desc="Over 4 million past domains sales" />
          <SingleStat title="12k" desc="Daily Lookups" />
          <SingleStat title="56K" desc="Daily API requests" />
        </div>
      </Container>
    </section>
  )
}
