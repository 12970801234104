import React from 'react';
import { NextSeo } from 'next-seo';
import Head from 'next/head';

interface Props {
  title?: string;
  keywords?: string;
  description?: string;
  canonical?: string; // New prop for canonical URL
  ogImage?: string; // New prop for dynamic og:image
  ogType?: string; // New prop for dynamic og:type
  ogTitle?: string; // New prop for dynamic og:title
  ogDescription?: string; // New prop for dynamic og:description
  ogUrl?: string; // New prop for dynamic og:url
}

const CustomNextSeo: React.FC<Props> = ({
  title,
  keywords,
  description,
  canonical,
  ogImage,
  ogType,
  ogTitle,
  ogDescription,
  ogUrl,
}) => {
  return (
    <>
      <NextSeo
        title={title || 'Bishopi.io'}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keywords || 'free domain name appraisal, domain name appraisal, domain name appraisal tool, domain name appraisal free, domain name appraisal free online, domain name appraisal free tool,',
          },
          {
            name: 'robots', // Add robots meta tag for indexing
            content: 'index, follow', // Allow search engines to index and follow links
          },
          {
            property: 'og:title',
            content: ogTitle || '#1 AI-Powered Domain Name Tools & Services', // Use the same title for og:title
          },
          {
            property: 'og:description',
            content: ogDescription || 'Free domain appraisal and domain investment tools', // Use the same description for og:description
          },
          {
            property: 'og:url',
            content: ogUrl || 'https://bishopi.io/', // Use the canonical URL or a default URL
          },
          {
            property: 'og:image',
            content: ogImage || 'https://bishopi.io/images/og-image.png', // Use the ogImage prop or a default image
          },
          {
            property: 'og:type',
            content:  ogType || 'website', // Use 'website' as the default type
          },
          {
            property: 'og:site_name',
            content: 'Bishopi.io', // Add site name
          },
        ]}
        description={description || 'Free domain appraisal and domain investment tools'}
      />
      <Head>
        {canonical && <link rel="canonical" href={canonical} />} {/* Add canonical link */}
      </Head>
    </>
  );
};

export default CustomNextSeo;
