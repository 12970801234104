import { H1 } from 'components/common/Texts/H1/H1'
import React from 'react'
import styles from './AnimatedH1.module.scss'

interface Props {}

export const AnimatedH1: React.FC<Props> = ({}) => (
  <H1 className={styles.h1}>
    A wide range of effective <br />
    domain name and SEO tools
  </H1>
)