import React, { HTMLAttributes } from 'react'
import styles from './FeatureTile.module.scss'
import Image from 'next/image'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'

interface Props extends HTMLAttributes<HTMLDivElement> {
  data: {
    id: number
    icon: string
    title: string
    description: string
    cta: string
  }
}

export const FeatureTile: React.FC<Props> = ({ data, ...props }) => {
  return (
    <FlexColumn className={styles.tile} {...props}>
      <Image src={data.icon} alt="icon" height="60" width="60" />
      <h5 className={styles.heading}>{data.title}</h5>
      <p
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: data.description }}
      />
      <a href={data.cta}>
        Learn More
        <Image
          src="/images/home/features/arrow.svg"
          alt="icon"
          height="7"
          width="22"
        />
      </a>
    </FlexColumn>
  )
}
